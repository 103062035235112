import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';
import { Box, Button } from "@mui/material";
import React from "react";
// import video from "../src/assets/video/version2.0.mp4";
import "./App.scss";
import Navbar from "./layout/Navbar";
import AboutUsSection from "./sections/AboutUs";
import Quote from "./sections/quote/Quote";
import Services from "./sections/services/Services";
import Technologies from "./sections/technologies/Technologies";
import Faq from './sections/faq/Faq';
import Footer from './layout/footer/Footer';
import Location from './sections/ourlocation/Location';
import ContactUs from './sections/contactus/ContactUs';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Partners from './sections/partners/Partners';
<Switch>
<Route path="/contact-us" exact>
  <ContactUs />
</Route>
{/* <Route path="/services" exact>
  <div className="servicesContainer">
    <Services />
  </div>
</Route> */}
{/* Add more routes for other sections as needed */}
{/* ... */}
</Switch>

function App() {
  return (
    <Router>
    <Switch>
    <div className="App">
    <Route path="/" exact>
        <Navbar showForContactus={false} />
        <Box className="HeaderVideo">
          <video autoPlay muted loop className="VideoTag">
            Your browser does not support the video tag.
            <source src={'https://firebasestorage.googleapis.com/v0/b/byblos-physio-clinic.appspot.com/o/assets%2Fvideo%2Fversion2.0.mp4?alt=media&token=0fdb5cb4-acca-4705-8947-5e430deb1054'} type="video/mp4" />
          </video>
          <div className="text-overlay">
            <a href="https://wa.me/+96170396957">
              <Button variant="contained" className="HeaderButton">
                Connect on WhatsApp
              </Button>
            </a>
            <div style={{ marginTop: "10px" }}>
              <a
                href="https://www.instagram.com/byblosphysioclinic/"
                target="_blank"
                className="SocialAnchors">
                <InstagramIcon fontSize="large"  className="SocialICON"/>
              </a>
              <a
                href="https://www.facebook.com/ByblosPhysioClinic?mibextid=ZbWKwL"
                target="_blank"
                className="SocialAnchors">
                <FacebookIcon fontSize="large"  className="SocialICON"/>
              </a>
              <a href="mailto:byblosphysioclinic@gmail.com" className="SocialAnchors">
                <MailIcon fontSize="large"  className="SocialICON"/>
                <span></span>
              </a>
            </div>
          </div>
        </Box>

        <AboutUsSection />
        <br/>
        <br/>
        <br/>

        
        <div className="technologiesContainer">
        <Technologies />
        </div>

        <div className="quoteContainer">
        <Quote />
        </div>
        <div className="servicesContainer">
        <Services />
        </div>


        <div className="partnersContainer">
        <Partners />
        </div>
       

{/* <Location /> */}

{/* <div className='contactusContainer'>
<ContactUs />
<br/>
</div> */}

        <div className='faqContainer'>
          <Faq />
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        {/* <div className='footerContainer'> */}
          <Footer />
        {/* </div> */}
    </Route>
    <Route path="/contact-us" exact>
    <Navbar showForContactus={true} />
    <div className='ContactusAppContainer'>
  <ContactUs />
  </div>
  <br/>
        <br/>
        <br/>
        <br/>
      <br/> 
         {/* <br/>
        <br/>
        <br/>
        <br/> */}
          <Footer />
</Route>
    </div>
    </Switch>
    </Router>
  );
}

export default App;
