import { Container, Typography } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import bestAssistanceLebanon from '../../assets/partners/bestAssistanceLebanon.png';
import image001 from '../../assets/partners/image001.png';
import IPTNoBgCutted from '../../assets/partners/IPTNoBgCutted.png';
import lauLogo from '../../assets/partners/lau-logo-retina-white.png';
import NextCare from '../../assets/partners/NextCare.svg';
import UnipakLogo from '../../assets/partners/UPAK-Logo-White180x40.png';
import USFEdited from '../../assets/partners/USFEdited.png';
import "./Partners.scss";

const SliderArray1 = [
  IPTNoBgCutted,
    image001,
    lauLogo,
    UnipakLogo,
    USFEdited,
    NextCare,
    bestAssistanceLebanon
  ];

  export const SliderList1 = SliderArray1.map((SliderArray1, index) => (
    <img alt="SliderArray1" className="LogoStyle" key={index} src={SliderArray1} />
  ));

function Partners() {
  return (
    <Container className="LogoSliderWrapper">
    <div className='PartnersTitle'>
        <br />
        <Typography variant={window.innerWidth > 900 ? 'h3' : 'h4'}>
          Our Partners
        </Typography>
        <br />
      </div>
      <Marquee className="MarqStyle1" speed="60" autoFill>
        {SliderList1}
      </Marquee>
    </Container>
  );
}

export default Partners;